<template>
  <div class="batchcontractDetail">
    <NavBar name="数据维护/复核"></NavBar>
    <div class="public-box" v-loading="loading">
      <div class="mask" v-if="form.contract_import_status == 20 && maskShow"></div>
      <el-table :data="tableData" style="width: 100%" :header-cell-style="{
        'text-align': 'center',
        background: 'rgb(245, 245, 245)',
      }" :cell-style="{ 'text-align': 'center' }">
        <el-table-column prop="merchants_stall_name" label="商户姓名"></el-table-column>
        <el-table-column prop="merchants_phone" label="联系电话"></el-table-column>
        <el-table-column prop="contract_card_no" label="证件号"></el-table-column>
        <el-table-column prop="contract_no" label="合同编号"></el-table-column>
        <el-table-column prop="contract_start" label="合同开始时间"></el-table-column>
        <el-table-column prop="contract_end" label="合同到期时间"></el-table-column>
      </el-table>
      <div class="batchcontractDetailForm">
        <el-form label-position="right" :model="form" ref="form" :rules="rules" label-width="150px">
          <!-- <el-form-item label="免租期:">
            <el-input class="labelwidth" v-model="form.rent_holiday" @blur="rentHolidayBlur" type="number">
              <template slot="append">个月</template>
            </el-input>
          </el-form-item>
          <el-form-item label="减免费用:" v-if="form.rent_holiday">
            <el-select v-model="form.contract_fee_type" multiple placeholder="请选择减免费用" class="labelwidth">
              <el-option v-for="item in reliefType" :key="item.id" :value="item.id" :label="item.contract_fee_name"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-date-picker v-model="rent_holiday_date" format="yyyy-MM-dd" value-format="yyyy-MM-dd" class="labelwidth" :disabled="true" type="daterange" range-separator="至" start-placeholder="免租开始日期" end-placeholder="免租结束日期"></el-date-picker>
          </el-form-item> -->
          <!-- <el-form-item label="免租金额">
            <el-input v-model="form.rent_free_price " class="labelwidth" placeholder="请输入免租金额"></el-input>
          </el-form-item> -->
          <el-form-item label="备注">
            <el-input v-model="form.remark" class="labelwidth" type="textarea" placeholder="备注"></el-input>
          </el-form-item>
          <rentalShops></rentalShops>
          <paymentAmount></paymentAmount>
          <contractData></contractData>
          <templateField />
          <!-- <el-form-item>

          </el-form-item> -->
        </el-form>
      </div>
    </div>

    <el-form label-width="150px">
      <el-form-item>
        <MyButton title="审核" right @click="submit" v-if="form.contract_import_status == 20"></MyButton>
        <MyButton title="提交" right @click="submit" v-else></MyButton>
        <MyButton title="取消" @click="$router.go(-1)"></MyButton>
        <MyButton title="修改" v-if="form.contract_import_status == 20" left @click="maskShow = false"></MyButton>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
import rentalShops from "./list/components/rentalShops.vue";
import paymentAmount from "./list/components/paymentAmount.vue";
import contractData from "./list/components/contractData.vue";
import templateField from "./list/components/templateField";
export default {
  name: "batchcontractDetail",
  components: {
    templateField,
    rentalShops,
    paymentAmount,
    contractData,
  },
  data () {
    return {
      maskShow: true,
      loading: false,
    };
  },
  computed: {
    ...mapState("contract", [
      "form",
      "rules",
      "reliefType",
      "tableData",
      "rent_holiday_date",
    ]),
  },
  async created () {
    this.loading = true;
    await this.getContract();
    await this.getMerchantsList();
    await this.getContractChargeSetList();
    await this.getDataMaintenance(this.$route.query.id);
    // await this.rentHolidayBlur();
    this.loading = false;
  },
  beforeDestroy () {
    this.initData();
  },
  methods: {
    ...mapActions("contract", [
      "getContract",
      "getMerchantsList",
      "getContractChargeSetList",
      "getDataMaintenance",
    ]),
    ...mapMutations("contract", [
      "setFormData",
      "setChangeMerchants",
      "setDateedShop",
      "calculateMonthlyAmount",
      "initProgressiveIncrease",
      "calculateIncrease",
      "calculateRentInfo",
      "setIscomplete",
      "initData",
      "setIntentionshop",
      "setChangeContract",
    ]),
    rentHolidayBlur () {
      if (!this.form.contract_start) {
        this.$common.notifyError("请选择合同开始时间");
        return;
      }
      if (!this.form.contract_end) {
        this.$common.notifyError("请选择合同结束时间");
        return;
      }
      if (!this.form.rent_holiday) {
        return;
      }
      let time = this.$common.monthsBetw(
        this.form.contract_start,
        this.form.contract_end
      );
      if (parseInt(this.form.rent_holiday) < 0) {
        this.form.rent_holiday = 0;
        return false;
      }
      if (parseInt(this.form.rent_holiday) >= time) {
        this.$common.notifyError("免租期时长不能等于或者大于合同时长");
        this.form.rent_holiday = 0;
        return;
      }
      let date = this.$common.addMonth(
        this.form.contract_start,
        this.form.rent_holiday
      );
      this.form.contract_fee_start = this.form.contract_start;
      let end = this.$common.formatDateTime(date);
      this.$set(this.rent_holiday_date, 0, this.form.contract_start);
      this.$set(this.rent_holiday_date, 1, end);

      this.form.contract_fee_end = end;
      this.form.rent_holiday = parseInt(this.form.rent_holiday);

      time = time - (this.form.rent_holiday || 0);
      let years = Math.ceil(time / 12);
      this.initProgressiveIncrease(years);
      this.calculateMonthlyAmount();
      this.calculateIncrease();
      this.calculateRentInfo();
    },
    initFormData (formData) {
      formData.contract_increasing &&
        formData.contract_increasing.forEach((item, indexz) => {
          item.fees_list &&
            item.fees_list.forEach((ite) => {
              ite.contract_rentInfo &&
                ite.contract_rentInfo.forEach((itm, index) => {
                  itm.pay_price = itm.pay_price * 1;
                  itm.property_end_time = itm.pay_end_time;
                  itm.property_time = formData.contract_start;

                  // 获取前一个账单周期开始时间
                  if (index > 0) {
                    itm.property_time = this.$common.formatTimeHms(
                      new Date(
                        ite.contract_rentInfo[index - 1].pay_end_time
                      ).getTime() +
                      24 * 60 * 60 * 1000
                    );
                  }
                  // 处理跨年获取前一个账单周期开始时间
                  if (indexz > 0 && index == 0) {
                    const lastTimeFeesList =
                      formData.contract_increasing[indexz - 1].fees_list;
                    const lastContractRentInfo =
                      lastTimeFeesList[lastTimeFeesList.length - 1]
                        .contract_rentInfo;
                    const lastDate =
                      lastContractRentInfo[lastContractRentInfo.length - 1]
                        .pay_end_time;
                    itm.property_time = this.$common.formatTimeHms(
                      new Date(lastDate).getTime() + 24 * 60 * 60 * 1000
                    );
                  }
                });
            });
        });
    },
    submit () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          let formData = JSON.parse(JSON.stringify(this.form));
          this.initFormData(formData);
          if (!this.form.contract_fee_setting_list) {
            let temp = [];
            for (let i in this.form.contract_fee_setting) {
              for (let j in this.form.contract_fee_setting[i]) {
                if (j != 0) {
                  temp.push(this.form.contract_fee_setting[i][j]);
                }
              }
            }
            this.setFormData({
              name: "contract_fee_setting_list",
              data: temp,
            });
          }
          this.$request
            .HttpPost("/contract/maintain", {
              ...this.form,
              ...{ contract_increasing: formData.contract_increasing },
            })
            .then((res) => {
              this.form.contract_import_status = this.form.contract_import_status == 10 ? 20 : this.form.contract_import_status == 20 ? 0 : "";
              this.$common.notifySuccess(res.msg);
              this.$router.go(-1);
            }).finally(() => {
              this.setIscomplete(false);
            });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.public-box {
  position: relative;
  height: 100%;

  .mask {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba($color: #000000, $alpha: 0.2);
    z-index: 5;
  }
}

.batchcontractDetailForm {
  margin: 20px 0;

  .labelwidth {
    width: 400px !important;
  }
}

.labelwidth {
  width: 400px !important;
}

.inputWidth {
  width: 250px !important;
}

.rentalBox {
  display: flex;
  align-items: center;

  .right {
    margin-left: 30px;
  }
}

.upload-demo {
  /deep/ .el-upload {
    text-align: left;
  }
}
</style>
